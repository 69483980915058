import { getTagById } from '../../../services/tags';
import React, { useState, useEffect, useMemo } from 'react';
import AdminNewCategory from './nuevo';
import { navigate } from 'gatsby';

const EditTag = ({ params: { id } }: { params: { id: string } }) => {
  const [loading, isLoading] = useState(true);
  const [tag, setTag] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    isLoading(true);
    fetchDataById();
    isLoading(false);
  }, []);

  const fetchDataById = async () => {
    const dataToUpdate = await getTagById(id);
    if (!dataToUpdate.data) {
      navigate(`/admin/tags-de-noticias`);
      return;
    }
    setTag(dataToUpdate.data);
  }

  const newComponent = useMemo(() =>
    <AdminNewCategory
      id={id}
      name={tag?.name}
      loading={loading}
    />, [tag]);

  return newComponent;
}

export default EditTag;
